import React from 'react'
import ProjectItem from '../common/ProjectItem'

const ProjectsContainer = ({listOfProjectNodes}) => {
  const renderProjects = projects => {
    let magicFactor = 0
    return projects.map((project, index) => {
        magicFactor += index
        return <ProjectItem
          withTitle
          titleColored
          key={project.node.title}
          project={project.node}
          sizeClass={magicFactor % 2 === 0 ? 'col-md-7' : 'col-md-5'}
        />
      }
    )
  }

  return (
    <section id='selected-projects' className='projects-list'>
      <div className='container'>
        <div className='row'>{renderProjects(listOfProjectNodes)}</div>
      </div>
    </section>
  )
}

export default ProjectsContainer