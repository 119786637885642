import React from 'react'
import ProjectsContainer from "../components/projects/ProjectsContainer"
import NavBar from "../components/common/NavBar"
import Divider from "../components/common/Divider"
import Helmet from "react-helmet"
import Layout from '../layouts';
import { graphql } from "gatsby"

const ProjectsPage = ({ data, location }) => {

  const { phone, email } = data.footerInfo.contact
  const projectNodes = data.projects.edges

  return (
    <Layout>
      <Helmet
        title={'Projects | Zaven'}
        meta={[
          { name: 'description', content: 'Looking for a Polish developer that is fluent in Azure, React or iOS? Then check out our portfolio full of interesting case studies!' },
          { name: 'keywords', content: 'Poland software development, Azure, NodeJS, IT outsourcing, Kotlin, Swift, Java, React, .NET, JavaScript, Android, iOS' },
        ]}
      />
      <NavBar lightTheme phoneNumber={phone} email={email} location={location}/>
      <section className='py-5 bg-lightest'>
        <div className='container'>
          <p className='h1'>Selected projects</p>
        </div>
      </section>
      <Divider rainbow fullWidth />
      <ProjectsContainer listOfProjectNodes={projectNodes} />
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql`
 query ProjectsQuery {
   footerInfo: dataJson {
     legalName
     contact {
        phone
        phoneClean
        email
        skype
        skypeName
        workTimeUTC {
          from
          to
        }
     }
     address {
        office {
          street
          city
          country
          url
        }
     }
    }
  projects: allProjectsYaml(filter: {displayIn: {eq: "projects"}} sort: {fields: [order]}) {
    ...SelectedProjects
  }
}`
